import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d1c0473"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "issuance" }
const _hoisted_2 = { class: "issuance-inputs" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "actions" }
const _hoisted_6 = { id: "flightSummary" }
const _hoisted_7 = { class: "m-issue-ticket" }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_typography = _resolveComponent("m-typography")!
  const _component_MFinancialProfileCombobox = _resolveComponent("MFinancialProfileCombobox")!
  const _component_m_combobox = _resolveComponent("m-combobox")!
  const _component_m_textfield = _resolveComponent("m-textfield")!
  const _component_m_textarea = _resolveComponent("m-textarea")!
  const _component_m_button = _resolveComponent("m-button")!
  const _component_m_card = _resolveComponent("m-card")!
  const _component_m_flight_summary = _resolveComponent("m-flight-summary")!
  const _component_m_flight_travelers = _resolveComponent("m-flight-travelers")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", null, [
      _createVNode(_component_m_typography, { type: "display" }, {
        default: _withCtx(() => [
          _createTextVNode(" Add Information ")
        ]),
        _: 1
      }),
      _createVNode(_component_m_card, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_MFinancialProfileCombobox, {
              inputValue: _ctx.flightInformation.financialProfile.value,
              "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.flightInformation.financialProfile.value) = $event)),
              label: "Financial Profiles",
              itemValue: "value",
              itemLabel: "label",
              hasError: _ctx.flightInformation.financialProfile.hasError,
              errorMessage: _ctx.flightInformation.financialProfile.errorMessage,
              disabled: _ctx.isPnrFetched || _ctx.isFetchingPNR,
              options: _ctx.financialProfiles
            }, null, 8, ["inputValue", "hasError", "errorMessage", "disabled", "options"]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_m_combobox, {
                inputValue: _ctx.flightInformation.supplier.value,
                "onUpdate:inputValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.flightInformation.supplier.value) = $event)),
                options: _ctx.suppliers,
                itemValue: "value",
                itemLabel: "label",
                label: "Airline/Supplier",
                hasError: _ctx.flightInformation.supplier.hasError,
                errorMessage: _ctx.flightInformation.supplier.errorMessage,
                disabled: _ctx.isPnrFetched || _ctx.isFetchingPNR
              }, null, 8, ["inputValue", "options", "hasError", "errorMessage", "disabled"]),
              _createVNode(_component_m_textfield, {
                inputValue: _ctx.flightInformation.pnrNumber.value,
                "onUpdate:inputValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.flightInformation.pnrNumber.value) = $event)),
                inputValueModifiers: { trim: true },
                label: "PNR Number",
                hasError: _ctx.flightInformation.pnrNumber.hasError,
                errorMessage: _ctx.flightInformation.pnrNumber.errorMessage,
                disabled: _ctx.isPnrFetched || _ctx.isFetchingPNR
              }, null, 8, ["inputValue", "hasError", "errorMessage", "disabled"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_m_textarea, {
                inputValue: _ctx.flightInformation.comments.value,
                "onUpdate:inputValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.flightInformation.comments.value) = $event)),
                inputValueModifiers: { trim: true },
                label: "Comments",
                disabled: _ctx.isPnrFetched || _ctx.isFetchingPNR
              }, null, 8, ["inputValue", "disabled"]),
              (_ctx.isSabreSelected)
                ? (_openBlock(), _createBlock(_component_m_combobox, {
                    key: 0,
                    inputValue: _ctx.flightInformation.sabreAirline.value,
                    "onUpdate:inputValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.flightInformation.sabreAirline.value) = $event)),
                    options: _ctx.sabreAirlines,
                    itemValue: "value",
                    itemLabel: "label",
                    label: "Sabre Airline",
                    hasError: _ctx.flightInformation.sabreAirline.hasError,
                    errorMessage: _ctx.flightInformation.sabreAirline.errorMessage,
                    disabled: _ctx.isPnrFetched || _ctx.isFetchingPNR
                  }, null, 8, ["inputValue", "options", "hasError", "errorMessage", "disabled"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_m_button, {
              class: "issuance-action",
              disabled: !_ctx.canFetchPnrDetails || _ctx.isPnrFetched,
              type: "filled",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.canFetchPnrDetails ? _ctx.handleFetchPnrDetails() : ''))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.isFetchingPNR ? "Fetching PNR" : "Get PNR Details"), 1)
              ]),
              _: 1
            }, 8, ["disabled"]),
            _createVNode(_component_m_button, {
              class: "issuance-action",
              disabled: _ctx.infoHasErrors() || !_ctx.isPnrFetched,
              type: "outlined",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.infoHasErrors() || !_ctx.isPnrFetched ? '' : _ctx.handleDataReset()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Reset ")
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          _withDirectives(_createVNode(_component_m_typography, { type: "label" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.sabreAirlineInfoMessage), 1)
            ]),
            _: 1
          }, 512), [
            [_vShow, _ctx.showSabreAirlineInfoMessage]
          ])
        ]),
        _: 1
      })
    ]),
    (_ctx.isPnrFetched && !_ctx.isTicketIssued)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("section", _hoisted_6, [
            _createVNode(_component_m_typography, { type: "display" }, {
              default: _withCtx(() => [
                _createTextVNode(" Flight Summary ")
              ]),
              _: 1
            }),
            _createVNode(_component_m_flight_summary, {
              totalPricing: _ctx.totalPricing,
              agPricing: _ctx.agPricing,
              flightDetails: _ctx.flightDetails,
              travelersCount: _ctx.travelers ? _ctx.travelers.length : 0
            }, null, 8, ["totalPricing", "agPricing", "flightDetails", "travelersCount"])
          ]),
          _createElementVNode("section", null, [
            _createVNode(_component_m_typography, { type: "display" }, {
              default: _withCtx(() => [
                _createTextVNode(" Travelers ")
              ]),
              _: 1
            }),
            _createVNode(_component_m_flight_travelers, { travelers: _ctx.travelers }, null, 8, ["travelers"])
          ]),
          _createElementVNode("section", null, [
            _createVNode(_component_m_typography, { type: "display" }, {
              default: _withCtx(() => [
                _createTextVNode(" Issue Ticket ")
              ]),
              _: 1
            }),
            _createVNode(_component_m_card, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_m_textfield, {
                    class: "m-otp",
                    inputValue: _ctx.OTP.value,
                    "onUpdate:inputValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.OTP.value) = $event)),
                    label: "OTP",
                    disabled: _ctx.isIssueTicketLoading,
                    hasError: _ctx.OTP.hasError,
                    errorMessage: _ctx.OTP.errorMessage,
                    onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.handleFlightIssuanceErrors()))
                  }, null, 8, ["inputValue", "disabled", "hasError", "errorMessage"]),
                  _createVNode(_component_m_button, {
                    class: "m-issue-ticket-action",
                    disabled: !_ctx.canIssueFlightTicket,
                    type: "filled",
                    onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.canIssueFlightTicket ? _ctx.handleFlightTicketIssuance() : ''))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Issue Ticket ")
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ])
              ]),
              _: 1
            })
          ])
        ], 64))
      : (_ctx.isTicketIssued)
        ? (_openBlock(), _createElementBlock("section", _hoisted_8, [
            _createVNode(_component_m_typography, { type: "display" }, {
              default: _withCtx(() => [
                _createTextVNode(" Issuance Status ")
              ]),
              _: 1
            }),
            _createVNode(_component_m_card, { class: "m-success-message" }, {
              default: _withCtx(() => [
                _createTextVNode(" Ticket Issued Successfully ")
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
  ]))
}