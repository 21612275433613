import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11dd476e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-price" }
const _hoisted_2 = { class: "m-flights" }
const _hoisted_3 = { class: "m-title" }
const _hoisted_4 = { class: "m-icon" }
const _hoisted_5 = { class: "m-body" }
const _hoisted_6 = { class: "m-timeline" }
const _hoisted_7 = { class: "date" }
const _hoisted_8 = { class: "m-icon" }
const _hoisted_9 = { class: "date" }
const _hoisted_10 = {
  key: 0,
  class: "m-baggage-info"
}
const _hoisted_11 = { class: "m-flight" }
const _hoisted_12 = { class: "m-body" }
const _hoisted_13 = { class: "m-timeline" }
const _hoisted_14 = { class: "date" }
const _hoisted_15 = { class: "m-flight" }
const _hoisted_16 = { class: "m-body" }
const _hoisted_17 = { class: "m-timeline" }
const _hoisted_18 = { class: "date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_typography = _resolveComponent("m-typography")!
  const _component_m_flight_take_off_icon = _resolveComponent("m-flight-take-off-icon")!
  const _component_m_flight_timeline_icon = _resolveComponent("m-flight-timeline-icon")!
  const _component_m_card = _resolveComponent("m-card")!

  return (_openBlock(), _createBlock(_component_m_card, { class: "m-flight-summary" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(_component_m_typography, { type: "headline" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.typeOfTrip), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_m_typography, { type: "label" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.travelersCount) + " Traveler" + _toDisplayString(_ctx.travelersCount > 1 ? "s" : ""), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_m_typography, {
            type: "headline",
            class: "m-current-price"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.isAgPricingNull ? _ctx.totalPricing : _ctx.agPricing), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_m_typography, {
          class: "m-header",
          type: "title"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Selected Flight" + _toDisplayString(_ctx.flightDetails.length > 1 ? "s" : ""), 1)
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flightDetails, (flight, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "m-flight"
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, [
                _createVNode(_component_m_flight_take_off_icon)
              ]),
              _createVNode(_component_m_typography, {
                type: "body",
                class: "m-text"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Flight " + _toDisplayString(_ctx.flightDetails.length > 1 ? index + 1 : "") + " · " + _toDisplayString(_ctx.formattedDate(flight.departure_date_time)), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_m_typography, { type: "body" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.formattedTime(flight.departure_date_time)), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_m_typography, { type: "label" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(flight.departure_short_code), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _createElementVNode("span", _hoisted_8, [
                  _createVNode(_component_m_flight_timeline_icon)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_m_typography, { type: "body" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.formattedTime(flight.arrival_date_time)), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_m_typography, { type: "label" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(flight.arrival_short_code), 1)
                    ]),
                    _: 2
                  }, 1024)
                ])
              ]),
              (_ctx.baggageInfo(flight.baggage_info))
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createVNode(_component_m_typography, { type: "label" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.baggageInfo(flight.baggage_info)), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        }), 128)),
        _createVNode(_component_m_typography, {
          class: "m-header",
          type: "title"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Booking Price ")
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_m_typography, {
                  type: "body",
                  class: _normalizeClass(`${_ctx.isAgPricingNull ? '' : 'm-current-price'}`)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.agPricing), 1)
                  ]),
                  _: 1
                }, 8, ["class"]),
                _createVNode(_component_m_typography, { type: "label" }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Ag Pricing ")
                  ]),
                  _: 1
                })
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_m_typography, {
                  type: "body",
                  class: _normalizeClass(`${_ctx.isAgPricingNull ? 'm-current-price' : ''}`)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.totalPricing), 1)
                  ]),
                  _: 1
                }, 8, ["class"]),
                _createVNode(_component_m_typography, { type: "label" }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Gross Fare ")
                  ]),
                  _: 1
                })
              ])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}